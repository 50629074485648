import axios from 'axios'

const http = (options = {}) => {
  const token = localStorage.getItem('token')
  const defaultOptions = {
    baseURL: process.env.VUE_APP_API_BASE,
    headers: {
      Authorization: `Bearer ${token}`
    }
  }

  const opts = Object.assign({}, defaultOptions, options)

  let http = axios.create(opts)

  http.interceptors.response.use(response => response.data)

  return http
}

export default http
