<template>
  <div class="flex flex-col h-full">
    <h1 class="mb-2 text-18px font-roboto">Setting</h1>
    <div
      class="border border-theme-border rounded-md bg-white shadow-md p-6 grid grid-cols-10 grid-flow-col gap-6"
    >
      <div class="col-span-4">
        <p class="font-roboto text-18px mb-4">
          Configure Following App Settings.
        </p>
        <BlockSwitchCard text="VPN" class="mb-2" v-model="switchVPN" />
        <BlockSwitchCard text="RDP" class="mb-2" v-model="switchRDP" />
        <BlockSwitchCard text="PROXY" class="mb-12" v-model="switchProxy" />

        <p class="font-roboto text-18px font-bold mb-2">
          Blocked Message
        </p>
        <p class="font-roboto text-17px text-gray-400 mb-6 font-normal">
          Iploged provides you a fully customizable restricted message to choose
          from which Blocked Visitors will see when trying to access your store.
        </p>
        <input
          type="text"
          placeholder="Title"
          class="w-full rounded-md border border-theme-border focus:border-black placeholder-theme-placeholder focus:outline-none px-4 py-2 mb-2"
          v-model="blockTitle"
        />

        <textarea
          type="text"
          placeholder="Description"
          class="w-full rounded-md border border-theme-border focus:border-black placeholder-theme-placeholder focus:outline-none px-4 py-2 mb-2"
          style="resize: none;"
          rows="6"
          v-model="blockDescription"
        ></textarea>

        <button
          class="focus:outline-none bg-secondary py-2 px-4 font-light rounded-lg text-white font-roboto hover:opacity-90 float-right"
          :class="{ 'opacity-80 pointer-events-none': isSaving }"
          @click="onClickSave"
        >
          {{ saveText }}
        </button>
      </div>

      <div class="col-span-6 relative flex flex-col">
        <p class="font-roboto text-18px text-center mb-4">
          Preview Page
        </p>

        <div class="border border-theme-border rounded-md bg-white p-12 flex-1">
          <h2 class="text-center text-3xl mb-4">{{ displayedBlockTitle }}</h2>
          <p class="text-center">
            {{ displayedBlockDescription }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockSwitchCard from '@/components/BlockSwitchCard'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  created() {
    this.getConfigurations()
  },
  data() {
    return {
      isSaving: false
    }
  },
  computed: {
    ...mapGetters('configurations', [
      'block_title',
      'block_description',
      'is_block_vpn',
      'is_block_rdp',
      'is_block_proxy'
    ]),
    displayedBlockTitle() {
      return this.blockTitle || 'Restricted Access'
    },
    displayedBlockDescription() {
      return (
        this.blockDescription ||
        'Sorry, you cannot visit our store from your current location.'
      )
    },
    blockTitle: {
      get() {
        return this.block_title
      },
      set(val) {
        this.setBlockTitle(val)
      }
    },
    blockDescription: {
      get() {
        return this.block_description
      },
      set(val) {
        this.setBlockDescription(val)
      }
    },
    switchVPN: {
      get() {
        return this.is_block_vpn
      },
      set(val) {
        this.setIsBlockVpn(val)
      }
    },
    switchRDP: {
      get() {
        return this.is_block_rdp
      },
      set(val) {
        this.setIsBlockRdp(val)
      }
    },
    switchProxy: {
      get() {
        return this.is_block_proxy
      },
      set(val) {
        this.setIsBlockProxy(val)
      }
    },
    saveText() {
      return this.isSaving ? 'Saving...' : 'Save'
    }
  },
  methods: {
    ...mapActions('configurations', [
      'getConfigurations',
      'saveConfigurations'
    ]),
    ...mapMutations('configurations', [
      'setIsBlockVpn',
      'setIsBlockRdp',
      'setIsBlockProxy',
      'setBlockTitle',
      'setBlockDescription'
    ]),
    async onClickSave() {
      this.isSaving = true
      await this.saveConfigurations()
      this.isSaving = false
    }
  },
  components: {
    BlockSwitchCard
  }
}
</script>
