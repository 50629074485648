<template>
  <div
    class="border border-theme-border rounded-md bg-white py-2 px-3 flex justify-between"
  >
    <p class="font-roboto text-18px font-medium">
      Block Visitors using {{ text }}
    </p>
    <SwitchInput small :value="value" @input="val => $emit('input', val)" />
  </div>
</template>

<script>
import SwitchInput from '@/components/SwitchInput'

export default {
  props: {
    text: {
      type: String
    },
    value: {
      type: Boolean
    }
  },
  components: {
    SwitchInput
  }
}
</script>

<style></style>
