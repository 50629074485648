import axios from 'axios'
import Vuex from 'vuex'
import Vue from 'vue'
import configurations from './configurations'
import logs from './logs'

Vue.use(Vuex)

let updateAlias = (data, token) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE}/logs/alias/update`,
    {
      aliases: data
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
let toggleLogging = (enable, token) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE}/logs/toggle/logging`,
    {
      enable: enable
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
let toggleEmailBeforeDelete = (enable, token) => {
  return axios.post(
    `${process.env.VUE_APP_API_BASE}/logs/toggle/email`,
    {
      enable: enable
    },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  )
}
let showErrorToast = err => {
  Vue.$toast.open({
    message: `Error : ${err.response.data.error}`,
    type: 'error',
    dismissible: true
  })
  console.log(err)
}

const store = new Vuex.Store({
  modules: {
    configurations,
    logs
  },
  state: {
    aliases: [],
    profile: null,
    token: '',
    isLoading: false
  },
  actions: {
    toggleEmailBeforeDelete({ commit, state }, isLogging) {
      if (!isLogging) {
        return toggleEmailBeforeDelete(false, state.token)
          .then(response => {
            if (response.status === 200) {
              commit('SET_EMAIL_BEFORE_LOG', false)
            }
            return response
          })
          .catch(err => {
            showErrorToast(err)
          })
      } else {
        return toggleEmailBeforeDelete(true, state.token)
          .then(response => {
            if (response.status === 200) {
              commit('SET_EMAIL_BEFORE_LOG', true)
            }
            return response
          })
          .catch(err => {
            showErrorToast(err)
          })
      }
    },
    toggleLogging({ commit, state }, isLogging) {
      if (!isLogging) {
        return toggleLogging(false, state.token)
          .then(response => {
            if (response.status === 200) {
              commit('SET_LOGGING', false)
            }
            return response
          })
          .catch(err => {
            showErrorToast(err)
          })
      } else {
        return toggleLogging(true, state.token)
          .then(response => {
            if (response.status === 200) {
              commit('SET_LOGGING', true)
            }
            return response
          })
          .catch(err => {
            showErrorToast(err)
          })
      }
    },
    setAlias({ commit, state }, aliases) {
      commit('SET_ALIAS', aliases)
      return updateAlias(state.aliases, state.token)
        .then(response => {
          if (response.status === 200) {
            commit('SET_ALIAS', response.data.aliases)
          }
          return response
        })
        .catch(err => {
          showErrorToast(err)
        })
    },
    addAlias({ commit, state }, alias) {
      commit('ADD_ALIAS', alias)
      return updateAlias(state.aliases, state.token)
        .then(response => {
          if (response.status === 200) {
            commit('SET_ALIAS', response.data.aliases)
          }
          return response
        })
        .catch(err => {
          showErrorToast(err)
        })
    }
  },
  getters: {
    hasPaidPlan(state) {
      if (!state.profile) {
        return false
      }
      if (state.profile.plan_name) {
        return state.profile.plan_name.length > 0
      }
      return false
    },
    shopName(state) {
      if (state.profile) {
        return state.profile.name
      }
      if (window.location.hostname.includes('myshopify.com')) {
        return window.location.hostname
      }
      return ''
    },
    token(state) {
      // we'll handle security exception from component
      try {
        if (state.token === '') {
          if (localStorage.getItem('token'))
            state.token = localStorage.getItem('token')
          else {
            return ''
          }
        }
        return state.token
      } catch (e) {
        console.log(e)
        return ''
      }
    },
    logging(state) {
      if (state.profile === null) {
        return true
      }
      return state.profile.sync
    },
    emailBeforeDelete(state) {
      if (state.profile == null) {
        return true
      }

      return state.profile.email_before_deleting
    },
    isLoading(state) {
      return state.isLoading
    }
  },
  mutations: {
    SET_EMAIL_BEFORE_LOG(state, isSet) {
      state.profile.email_before_deleting = isSet
    },
    SET_TOKEN(state, token) {
      // We'll handle exception from component.
      try {
        localStorage.removeItem('token')
        localStorage.setItem('token', token)
        state.token = token
      } catch (e) {
        console.log(e)
      }
    },
    SET_LOGGING(state, logging) {
      state.profile.sync = logging
    },
    SET_PROFILE(state, profile) {
      state.profile = profile
    },
    SET_ME(state, me) {
      state.me = me
    },
    SET_ALIAS(state, aliases) {
      if (aliases === null) {
        state.aliases = []
        return
      }
      state.aliases = aliases
    },
    ADD_ALIAS(state, alias) {
      state.aliases.push(alias)
    },
    SET_LOADING(state, isLoading) {
      state.isLoading = isLoading
    }
  }
})

export default store
