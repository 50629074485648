<template>
  <div class="flex flex-col justify-center items-center">
    <table class="w-full text-left mb-6">
      <thead>
        <tr class="bg-theme-thead bg-opacity-80">
          <th class="font-normal font-sf py-3 px-5">IP Addresses</th>
          <th class="text-center font-normal font-sf py-3 px-5">Country</th>
          <th class="font-normal font-sf py-3 px-5">Time</th>
          <th class="font-normal font-sf py-3 px-5">Browser</th>
          <th class="font-normal font-sf py-3 px-5">Device</th>
          <th class="font-normal font-sf py-3 px-5">Bot</th>
          <th class="font-normal font-sf py-3 px-5">OS</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(log, index) in logs" :key="index">
          <td class="font-sf py-3 px-5">{{ log.ip_address }}</td>
          <td class="font-sf py-3 px-5">
            <div class="flex justify-center">
              <img
                :src="`https://flagcdn.com/w40/${log.country.toLowerCase()}.png`"
                :alt="countries[log.country] ? countries[log.country].name : ''"
                class="rounded-full h-6 w-6 object-none mr-2 shadow-xl"
              />
            </div>
          </td>
          <td class="font-sf py-3 px-5">{{ log.created_at | fromNow }}</td>
          <td class="font-sf py-3 px-5">{{ log.user_agent | browser }}</td>
          <td class="font-sf py-3 px-5">{{ log.user_agent | device }}</td>
          <td class="font-sf py-3 px-5">No</td>
          <td class="font-sf py-3 px-5">{{ log.user_agent | os }}</td>
        </tr>
        <tr v-if="!logs || !logs.length">
          <td colspan="7" class="text-center">
            No Logs Available
          </td>
        </tr>
      </tbody>
    </table>
    <div class="">
      <GeneralPagination
        :itemCount="itemCount"
        :per-page="perPage"
        @change="pageChange"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

import GeneralPagination from '@/components/GeneralPagination'

import countries from '@/constants/countries'

export default {
  props: {
    logs: {
      type: Array,
      default: () => []
    },
    itemCount: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      countries
    }
  },
  methods: {
    pageChange(page) {
      this.$emit('pageChange', page)
    }
  },
  filters: {
    browser(userAgent) {
      if ((userAgent.indexOf('Opera') || userAgent.indexOf('OPR')) != -1)
        return 'Opera'
      else if (
        userAgent.indexOf('Chrome') != -1 &&
        userAgent.indexOf('Chromium') < 0
      )
        return 'Chrome'
      else if (userAgent.indexOf('Chromium') != -1) return 'Chromium'
      else if (userAgent.indexOf('Safari') != -1) return 'Safari'
      else if (userAgent.indexOf('Firefox') != -1) return 'Firefox'
      else if (userAgent.indexOf('MSIE') != -1) return 'IE'
      else return 'unknown'
    },
    device(userAgent) {
      if (userAgent.indexOf('Mobile') != -1) return 'Mobile'
      else return 'Desktop'
    },
    os(userAgent) {
      if (userAgent.indexOf('Android') != -1) return 'Android'
      else if (userAgent.indexOf('iPhone') != -1) return 'iOS'
      else if (userAgent.indexOf('Windows') != -1) return 'Windows'
      else if (
        userAgent.indexOf('Macintosh') != -1 &&
        userAgent.indexOf('AppleWebKit') != -1
      )
        return 'iPad OS'
      else if (userAgent.indexOf('Macintosh') != -1) return 'Mac OS'
      else if (userAgent.indexOf('Linux') != -1) return 'Linux'
      else return 'Unknown'
    },
    fromNow(date) {
      return moment(date).fromNow()
    }
  },
  components: {
    GeneralPagination
  }
}
</script>

<style lang="scss" scoped>
/** thead tbody spacing */
tbody:before {
  content: '@';
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}
</style>
