<template>
  <div class="rounded-md flex flex-col items-center">
    <p class="text-center text-15px font-bold font-sf tracking-wider max-w-md">
      you can now set your google ads to be shown to only specific countries
    </p>
    <p class="text-center text-15px w-11/12 font-sf mb-3 max-w-md">
      Strategically position your ads to the correct audience. Exclude visitors
      you think aren’t likely your potential buyer.
    </p>
    <a
      href="https://support.google.com/google-ads/answer/2456098?hl=en"
      target="_blank"
      class="focus:outline-none font-roboto text-white rounded-md flex items-center justify-center py-1 px-4 bg-info hover:opacity-90"
    >
      <img src="@/assets/img/info-icon.svg" alt="Info Icon" class="mr-3" />
      Learn More
    </a>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
