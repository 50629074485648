import http from '@/constants/http'
import fileDownload from 'js-file-download'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    logs: [],
    totalLogs: 0,
    blockedLogs: [],
    totalBlockedLogs: 0
  },
  getters: {
    logs: ({ logs }) => logs,
    totalLogs: ({ totalLogs }) => totalLogs,
    blockedLogs: ({ blockedLogs }) => blockedLogs,
    totalBlockedLogs: ({ totalBlockedLogs }) => totalBlockedLogs
  },
  mutations: {
    setLogs(state, payload) {
      state.logs = payload
    },
    setTotalLogs(state, payload) {
      state.totalLogs = payload
    },
    setBlockedLogs(state, payload) {
      state.blockedLogs = payload
    },
    setTotalBlockedLogs(state, payload) {
      state.totalBlockedLogs = payload
    }
  },
  actions: {
    async getLogs(
      { commit },
      { limit, offset, search } = { limit: 7, offset: 0, search: '' }
    ) {
      const res = await http().get('logs/list', {
        params: { limit, offset, search, blocked: false }
      })

      commit('setLogs', res.logs)
      commit('setTotalLogs', res.count)
    },
    async getBlockedLogs(
      { commit },
      { limit, offset, search } = { limit: 7, offset: 0, search: '' }
    ) {
      const res = await http().get('logs/list', {
        params: { limit, offset, search, blocked: true }
      })

      commit('setBlockedLogs', res.logs)
      commit('setTotalBlockedLogs', res.count)
    },
    async downloadLogs(
      _,
      { from, to } = {
        from: moment()
          .subtract(30, 'days')
          .format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD')
      }
    ) {
      const res = await http().get('logs/download', {
        params: { from, to },
        responseType: 'blob'
      })

      fileDownload(res, 'blocked-logs.zip')
    }
  }
}
