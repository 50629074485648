<template>
  <div
    class="rounded-xl bg-gradient-to-b from-secondary to-primary p-6 relative"
  >
    <h1 class="text-white font-roboto text-20px mb-4">
      Welcome to <span class="font-bold">IPLoged</span>
    </h1>
    <p class="text-white font-roboto font-normal w-3/4 max-w-4xl">
      An advanced solution that allows store owners an opportunity to provide a
      quicker response to security while reducing possible disputes and
      chargeback. A tool that is designed to help users effectively identify any
      suspicious activities and be able to conduct business without any threats.
    </p>

    <img src="@/assets/img/map.svg" alt="Map" class="absolute right-10 top-2" />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
