<template>
  <div
    class="h-sidebar w-52 rounded-xl bg-gradient-to-r from-secondary to-primary flex flex-col relative"
  >
    <img
      src="@/assets/img/ip-logo.svg"
      alt="IP Logged Logo"
      class="h-16 mb-32 mt-4"
    />

    <div class="grid gap-4">
      <router-link
        v-for="(menu, index) in menus"
        :key="index"
        :to="menu.path"
        class="flex text-white hover:text-white hover:opacity-90 px-5 py-3 items-center text-19px font-bold font-roboto"
      >
        <img :src="menu.icon" :alt="menu.name" class="mr-5 w-7" />
        {{ menu.name }}
      </router-link>
    </div>

    <img
      src="@/assets/img/ip-logo--bg.svg"
      alt="IP Logged Logo"
      class="h-40 absolute bottom-0 right-0"
    />
  </div>
</template>

<script>
import menus from '@/constants/menus'

export default {
  data() {
    return {
      menus
    }
  }
}
</script>
