<template>
  <div class="help flex flex-col h-full">
    <h1 class="mb-2 text-15px font-sf font-bold">Store Visits</h1>
    <div class="border border-theme-border rounded-md bg-white shadow-md p-6">
      <VisitsTable
        :logs="logs"
        :itemCount="totalLogs"
        :per-page="perPage"
        @pageChange="pageChange"
      />
    </div>
  </div>
</template>

<script>
import VisitsTable from '@/components/VisitsTable'
import { mapActions, mapGetters } from 'vuex'

export default {
  created() {
    this.getLogs({
      offset: 0,
      limit: this.perPage
    })
  },
  data() {
    return {
      perPage: 11
    }
  },
  computed: {
    ...mapGetters('logs', ['logs', 'totalLogs'])
  },
  methods: {
    ...mapActions('logs', ['getLogs']),
    pageChange(page) {
      this.getLogs({
        offset: this.perPage * (page - 1),
        limit: this.perPage
      })
    }
  },
  components: {
    VisitsTable
  }
}
</script>
