<template>
  <div class="h-full bg-theme-background" id="app">
    <div class="h-full flex p-8">
      <Sidebar class="mr-10" />
      <main class="w-full">
        <router-view />
      </main>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar'

export default {
  components: {
    Sidebar
  }
}
</script>
<style></style>
