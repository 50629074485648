<template>
  <div>
    <div v-show="signInAgain">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <h1 class="title mb-6">Please Sign-in Again</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="box" v-show="!signInAgain">
      <p>Signing you in...</p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Login',
  computed: {
    signInAgain() {
      return this.shop.length === 0
    },
    shop() {
      let uri = window.location.search.substring(1)
      let params = new URLSearchParams(uri)
      return params.get('shop')
    }
  },
  mounted() {
    if (this.shop.length > 0) {
      axios
        .get(`${process.env.VUE_APP_API_BASE}/signin/shopify?shop=${this.shop}`)
        .then(response => {
          const responseLink = response.data.link
          let redirectLink = responseLink

          if (process.env.VUE_APP_ENVIRONMENT === 'local')
            redirectLink = responseLink.replace(
              'https%3A%2F%2Fapp.iploged.com',
              window.location.origin
            )

          window.location = redirectLink
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style scoped></style>
