<template>
  <div class="relative">
    <svg
      class="absolute left-3 top-1/2 transform -translate-y-1/2"
      id="Group_48012"
      data-name="Group 48012"
      xmlns="http://www.w3.org/2000/svg"
      width="17.914"
      height="17.914"
      viewBox="0 0 17.914 17.914"
    >
      <g
        id="Ellipse_89"
        data-name="Ellipse 89"
        fill="none"
        stroke="#9da1d3"
        stroke-linecap="round"
        stroke-width="2"
      >
        <circle cx="7" cy="7" r="7" stroke="none" />
        <circle cx="7" cy="7" r="6" fill="none" />
      </g>
      <line
        id="Line_12"
        data-name="Line 12"
        x2="5"
        y2="5"
        transform="translate(11.5 11.5)"
        fill="none"
        stroke="#9da1d3"
        stroke-linecap="round"
        stroke-width="2"
      />
    </svg>

    <input
      type="text"
      class="border border-theme-border p-2 pl-10 w-full rounded-lg placeholder-theme-placeholder font-roboto focus:outline-none font-light"
      placeholder="Search countries"
      @input="onInput"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    onInput({ target }) {
      this.$emit('input', target.value)
    }
  }
}
</script>
