<template>
  <div></div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Auth',
  mounted() {
    let uri = window.location.search.substring(1)
    let params = new URLSearchParams(uri)

    axios
      .post(`${process.env.VUE_APP_API_BASE}/auth/shopify`, {
        code: params.get('code'),
        shop_name: params.get('shop')
      })
      .then((response) => {
        this.$store.commit('SET_TOKEN', response.data.token)
        this.$router.push(`/`)
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
</script>

<style scoped></style>
