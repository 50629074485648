import Vue from 'vue'
import App from '@/App.vue'
import router from './router'
import store from './store/store'
import VueToast from 'vue-toast-notification'

import '@/assets/css/style.scss'
import 'vue-toast-notification/dist/theme-default.css'

Vue.use(VueToast, {
  position: 'top-right'
})

Vue.config.productionTip = false

Vue.use(require('vue-moment'))

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
