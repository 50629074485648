<template>
  <div>
    <div v-show="notSupported">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <h1 class="title mb-6">Your browser is not supported.</h1>
            <h2 class="subtitle">
              You're either using the app in incognito mode or your browser
              doesn't allows third party cookies.
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isLoading && !hasPaidPlan">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <h1 class="title mb-6"></h1>
            <h2 class="subtitle">
            </h2>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!isSynced">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          <div class="box">
            <h1 class="title mb-6">Welcome to IPLoged</h1>
            <hr />
            <ul class="list">
              <li>
                IPLoged doesn't require any setup. Once linked to your store it
                will log any IP address that visit your store.
              </li>
              <li>
                You can tag specific IPs, whether it is your IP or a suspicious
                IP (Bot, spy tool..), and have them come up under specific names
                in the CSV exports of the logs.
              </li>
              <li>
                Finally, you can download logs from specific windows of times
              </li>
            </ul>
            <button class="button is-success" @click="dismiss">
              Dismiss
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AuthChecker',
  data() {
    return {
      notSupported: false,
      signInAgain: false,
      isSynced: true
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    },
    hasPaidPlan() {
      return this.$store.getters.hasPaidPlan
    },
    inIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }
  },
  methods: {
    fetchMe(callback) {
      return axios
        .get(`${process.env.VUE_APP_API_BASE}/me`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(response => {
          // if (response.data.aliases) {
          // this.$store.commit('SET_ALIAS', response.data.aliases.list)
          this.$store.commit('SET_PROFILE', response.data.profile)
          this.$store.commit('SET_LOADING', false)
          // }
          const firstVisited = localStorage.getItem('first-visit') || null
          if (!firstVisited && !response.data.profile.sync) {
            this.isSynced = false
            localStorage.setItem('first-visit', false)
          }
          localStorage.setItem('first-visit', false)
          // make sure the callback with execute after all statement run
          if (callback) callback()
          return response
        })
    },
    dismiss() {
      this.isSynced = true
    }
  },
  mounted() {
    // switch to loading state for prepare the check profile /me
    this.$store.commit('SET_LOADING', true)

    // First Check if localStorage API is accessible
    try {
      window.localStorage.getItem('test')
    } catch (e) {
      this.notSupported = true
      return
    }

    // Token Not found, redirect to login page.
    if (this.$store.getters.token.length === 0) {
      this.$router.push(`/login?shop=${this.$store.getters.shopName}`)
      return
    }

    this.fetchMe()
      .then(() => {
        if (!this.hasPaidPlan) {
          console.log(this.hasPaidPlan)
          axios
            .post(
              `${process.env.VUE_APP_API_BASE}/change_plan`,
              {
                plan_name: 'ONEPLAN'
              },
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(response => {
              window.location = response.data.link
            })
        }
      })
      .catch(err => {
        console.log(err)
        this.$router.push(`/login?shop=${this.$store.getters.shopName}`)
      })
  }
}
</script>

<style scoped>
.list {
  list-style: decimal;
}
.list li {
  padding-left: 8px;
  margin-left: 12px;
  margin-bottom: 16px;
}
</style>
