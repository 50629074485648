<template>
  <div class="flex items-center">
    <p
      class="font-roboto mr-4 font-medium"
      :class="{ 'text-12px': small, 'text-18px': !small }"
    >
      {{ displayText }}
    </p>
    <label class="switch" :class="{ 'switch--sm': small }">
      <input
        class="switch__checkbox"
        type="checkbox"
        @input="onInput"
        :checked="value"
      />
      <span class="switch__slider"></span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    },
    small: {
      type: Boolean
    }
  },
  data() {
    return {
      valHolder: false
    }
  },
  computed: {
    displayText() {
      return this.value || this.valHolder ? 'Activated' : 'Deactivated'
    }
  },
  methods: {
    onInput({ target }) {
      this.valHolder = target.checked
      this.$emit('input', target.checked)
    }
  }
}
</script>

<style lang="scss" scoped>
.switch {
  $switch: &;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  &--sm {
    width: 35px;
    height: 21px;

    #{$switch}__slider {
      &:before {
        height: 17px;
        width: 17px;
        left: 2px;
        bottom: 2px;
      }
    }

    #{$switch}__checkbox:checked + #{$switch}__slider:before {
      -webkit-transform: translateX(14px);
      -ms-transform: translateX(14px);
      transform: translateX(14px);
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: theme('colors.white');
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__checkbox:focus + &__slider {
    box-shadow: 0 0 1px theme('colors.lime');
  }

  &__checkbox:checked + &__slider {
    background-color: theme('colors.lime');
  }

  &__checkbox:checked + &__slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}
</style>
