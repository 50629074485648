<template>
  <div class="">
    <div class="flex justify-between items-center mb-4 h-10">
      <p class="text-14px">Available Countries</p>
      <a
        class="text-14px text-theme-link cursor-pointer hover:opacity-90"
        @click="onMoveAll"
      >
        Move All
      </a>
    </div>
    <div class="w-full flex flex-col">
      <SearchInput class="mb-4" v-model="searchInput" />
      <div class="h-64 overflow-y-auto">
        <div
          v-for="country in shownCountries"
          :key="country.code"
          class="flex py-2 px-1 cursor-pointer hover:bg-theme-hover rounded-lg group"
          @click="moveCountry(country)"
        >
          <span
            class="h-6 w-6 object-none mr-2 shadow-xl"
            v-bind:class="getFlagClass(country)"
          ></span>
          <p class="">{{ country.name }}</p>

          <img
            src="@/assets/img/arrow-right-icon.svg"
            alt="Arrow Right"
            class="hidden ml-auto mr-4 group-hover:block"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/SearchInput'
import { CTABS } from '@/constants/tabs'
import { mapMutations } from 'vuex'
import ALL_COUNTRIES from '@/constants/countries'

export default {
  data() {
    return {
      searchInput: ''
    }
  },
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    tabSelected: {
      type: String,
      default: CTABS.ALLOWED
    }
  },
  computed: {
    shownCountries() {
      return this.countries.filter(country =>
        country.name.toLowerCase().includes(this.searchInput.toLowerCase())
      )
    }
  },
  methods: {
    ...mapMutations('configurations', [
      'setSelectedCountries',
      'addSelectedCountry'
    ]),
    moveCountry(country) {
      this.addSelectedCountry(country.code)
    },
    onMoveAll() {
      const allCountries = Object.values(ALL_COUNTRIES).map(
        country => country.code
      )
      this.setSelectedCountries([...allCountries])
    },
    getFlagClass(country) {
      if (!country.code) {
          return 'bg-disabled';
      }

      return `flag-icon flag-icon-${country.code.toLowerCase()}`
    }
  },
  components: {
    SearchInput
  }
}
</script>
