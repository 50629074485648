<template>
  <div class="h-full flex flex-col">
    <WelcomeCard class="mb-8" />

    <div class="flex justify-between mb-4">
      <p class="text-18px font-roboto font-medium">
        Iploged allows you to completely block all internet traffic from a
        specific country to your store
      </p>
      <SwitchInput v-model="isAppEnabled" />
    </div>

    <div
      class="border border-theme-border rounded-md bg-white shadow"
      :class="{
        'opacity-50 pointer-events-none': isCancelling || !isAppEnabled
      }"
    >
      <div class="flex w-full border-b border-theme-border">
        <AvailableCountries
          :tab-selected="tabSelected"
          :countries="availableCountries"
          class="px-8 py-4 flex-1 border-r border-theme-border"
        />
        <SelectedCountries
          v-model="tabSelected"
          :countries="selectedCountries"
          class="px-8 py-4 flex-1"
        />
      </div>
      <div class="flex justify-between px-8 py-4">
        <button
          class="focus:outline-none bg-white border border-disabled py-2 px-4 font-light rounded-lg text-disabled font-roboto hover:opacity-90"
          @click="onClickCancel"
        >
          Cancel
        </button>

        <button
          class="focus:outline-none bg-secondary py-2 px-4 font-light rounded-lg text-white font-roboto hover:opacity-90"
          :class="{ 'opacity-80 pointer-events-none': isSaving }"
          @click="onClickSave"
        >
          {{ saveText }}
        </button>
      </div>
    </div>
    <!-- TODO: Move AuthChecker to a Router Guard -->
    <AuthChecker></AuthChecker>
  </div>
</template>

<script>
import AuthChecker from '@/components/AuthChecker'
import WelcomeCard from '@/components/WelcomeCard'
import AvailableCountries from '@/components/AvailableCountries'
import SelectedCountries from '@/components/SelectedCountries'
import SwitchInput from '@/components/SwitchInput'

import countries from '@/constants/countries'

import { CTABS } from '@/constants/tabs'
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  created() {
    this.getConfigurations()
  },
  data() {
    return {
      countriesOptions: Object.values(countries),
      isSaving: false,
      isCancelling: false
    }
  },
  computed: {
    ...mapGetters('configurations', [
      'selected_countries',
      'is_block_country',
      'is_app_enabled'
    ]),
    tabSelected: {
      get() {
        return this.is_block_country ? CTABS.BLOCKED : CTABS.ALLOWED
      },
      set(val) {
        this.setIsWhitelistCountry(val === CTABS.ALLOWED)
        this.setIsBlockCountry(val === CTABS.BLOCKED)
      }
    },
    isAppEnabled: {
      get() {
        return this.is_app_enabled
      },
      set(val) {
        this.toogleApp(val)
        this.saveConfigurations({ hasNotification: false })
      }
    },
    selectedCountries() {
      return this.selected_countries
    },
    availableCountries() {
      return this.countriesOptions.filter(country => {
        return !this.selectedCountries.some(
          selected => country.code === selected.code
        )
      })
    },
    saveText() {
      return this.isSaving ? 'Saving...' : 'Save'
    }
  },
  methods: {
    ...mapActions('configurations', [
      'getConfigurations',
      'saveConfigurations'
    ]),
    ...mapMutations('configurations', [
      'setIsWhitelistCountry',
      'setIsBlockCountry',
      'toogleApp'
    ]),
    async onClickSave() {
      this.isSaving = true
      await this.saveConfigurations()
      this.isSaving = false
    },
    async onClickCancel() {
      this.isCancelling = true
      await this.getConfigurations(true)
      this.isCancelling = false
    }
  },
  components: {
    WelcomeCard,
    AvailableCountries,
    SelectedCountries,
    SwitchInput,
    AuthChecker
  }
}
</script>
