<template>
  <div class="grid grid-flow-col gap-2" v-if="this.itemCount > this.perPage">
    <div
      class="cursor-pointer rounded-lg hover:bg-skyblue w-9 h-9 font-bold text-15px flex items-center justify-center group"
      @click="prev()"
      v-if="this.currentPage > 0"
    >
      <img
        src="@/assets/img/chevron-left-icon.svg"
        alt="Left Icon"
        class="filter group-hover:brightness-200"
      />
    </div>
    <div
      v-for="(_, index) in pages"
      :key="index"
      class="cursor-pointer rounded-lg hover:bg-skyblue hover:text-white w-9 h-9 font-bold text-15px flex items-center justify-center"
      :class="{ 'bg-skyblue text-white': _ + 1 === curPage }"
      @click="onPageClick(_ + 1)"
    >
      {{ _ + 1 }}
    </div>

    <div
      class="w-6 h-9 flex items-center justify-center"
      v-if="(this.currentPage + 1) * this.perPage < this.itemCount"
    >
      ...
    </div>
    <div
      class="cursor-pointer rounded-lg hover:bg-skyblue hover:text-white w-9 h-9 font-bold text-15px flex items-center justify-center"
      :class="{ 'bg-skyblue text-white': this.itemCount === curPage }"
      @click="onPageClick(itemCount)"
      v-if="(this.currentPage + 1) * this.perPage < this.itemCount"
    >
      {{ this.itemCount }}
    </div>
    <div
      class="cursor-pointer rounded-lg hover:bg-skyblue w-9 h-9 font-bold text-15px flex items-center justify-center group"
      @click="next()"
      v-if="(this.currentPage + 1) * this.perPage < this.itemCount"
    >
      <img
        src="@/assets/img/chevron-right-icon.svg"
        alt="Right Icon"
        class="filter group-hover:brightness-200"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itemCount: {
      type: Number
    },
    perPage: {
      type: Number,
      default: 7
    },
    value: {
      type: Number
    }
  },
  data() {
    return {
      valHolder: 1,
      currentPage: 0
    }
  },
  computed: {
    pages() {
      let arr = []
      for (let i = 0; i < this.itemCount; i++) arr.push(i)
      arr = arr.slice(
        this.currentPage * this.perPage,
        this.currentPage * this.perPage + this.perPage
      )
      return arr
    },
    curPage() {
      return this.value || this.valHolder
    }
  },
  methods: {
    prev() {
      if (this.currentPage - 1 >= 0) this.currentPage -= 1
    },
    next() {
      if ((this.currentPage + 1) * this.perPage < this.itemCount)
        this.currentPage += 1
    },
    onPageClick(val) {
      this.valHolder = val
      this.$emit('input', val)
      this.$emit('change', val)
    }
  }
}
</script>
