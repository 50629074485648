import Vue from 'vue'
import countries from '@/constants/countries'
import http from '@/constants/http'

export default {
  namespaced: true,
  state: {
    configuration: {
      block_title: '',
      block_description: '',
      block_vpn: false,
      block_rdp: false,
      block_proxy: false,
      block_country: false,
      whitelist_country: true,
      block_countries: [],
      whitelisted_countries: []
    },
    isLoaded: false,
    lastConfiguration: null,
    selected_countries: []
  },
  getters: {
    // Clean up this snake case mess and turn into camelCase.
    selected_countries: ({ selected_countries }) =>
      selected_countries
        .map(code => countries[code])
        .slice()
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1
        }),
    is_app_enabled: ({ configuration }) =>
      configuration.whitelist_country || configuration.block_country,
    block_title: ({ configuration }) => configuration.block_title,
    block_description: ({ configuration }) => configuration.block_description,
    is_block_vpn: ({ configuration }) => configuration.block_vpn,
    is_block_rdp: ({ configuration }) => configuration.block_rdp,
    is_block_proxy: ({ configuration }) => configuration.block_proxy,
    is_block_country: ({ configuration }) => configuration.block_country,
    blocked_countries: ({ configuration }) =>
      configuration.block_countries
        .map(code => countries[code])
        .slice()
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1
        }),
    is_whitelist_country: ({ configuration }) =>
      configuration.whitelist_country,
    whitelisted_countries: ({ configuration }) =>
      configuration.whitelisted_countries
        .map(code => countries[code])
        .slice()
        .sort((a, b) => {
          return a.name < b.name ? -1 : 1
        })
  },
  mutations: {
    setIsForce(state, payload) {
      state.isLoaded = payload
    },
    setBlockTitle(state, payload) {
      state.configuration.block_title = payload
    },
    setBlockDescription(state, payload) {
      state.configuration.block_description = payload
    },
    setIsBlockVpn(state, payload) {
      state.configuration.block_vpn = payload
    },
    setIsBlockRdp(state, payload) {
      state.configuration.block_rdp = payload

      state.configuration.block_vpn =
        state.configuration.block_rdp || state.configuration.block_proxy
    },
    setIsBlockProxy(state, payload) {
      state.configuration.block_proxy = payload

      state.configuration.block_vpn =
        state.configuration.block_rdp || state.configuration.block_proxy
    },
    setIsBlockCountry(state, payload) {
      state.configuration.block_country = payload
    },
    setSelectedCountries(state, payload) {
      state.selected_countries = payload
    },
    addSelectedCountry(state, payload) {
      state.selected_countries = [...state.selected_countries, payload]
    },
    removeSelectedCountry(state, payload) {
      state.selected_countries = state.selected_countries.filter(
        country => country !== payload
      )
    },
    setIsWhitelistCountry(state, payload) {
      state.configuration.whitelist_country = payload
    },
    toogleApp(state, payload) {
      if (payload && state.lastConfiguration) {
        state.configuration = {
          ...state.lastConfiguration
        }
        return
      } else if (payload) {
        state.configuration.whitelist_country = payload
        return
      }

      // Save last state
      state.lastConfiguration = JSON.parse(JSON.stringify(state.configuration))
      state.configuration.block_vpn = payload
      state.configuration.block_rdp = payload
      state.configuration.block_proxy = payload
      state.configuration.block_country = payload
      state.configuration.whitelist_country = payload
    }
  },
  actions: {
    async getConfigurations({ commit, state }, isForce) {
      if (state.isLoaded && !isForce) return

      const configuration = await http().get('configs')

      commit('setIsForce', true)
      commit('setBlockTitle', configuration.block_title)
      commit('setBlockDescription', configuration.block_description)
      commit('setIsBlockVpn', configuration.block_vpn)
      commit('setIsBlockRdp', configuration.block_rdp)
      commit('setIsBlockProxy', configuration.block_proxy)
      commit('setIsBlockVpn', configuration.block_vpn)
      commit('setIsBlockCountry', configuration.block_country)
      commit('setIsWhitelistCountry', configuration.whitelist_country)

      const blockedCountries = configuration.block_countries || []
      const whitelistedCountries = configuration.whitelisted_countries || []
      const countriesMap = [
        ...blockedCountries,
        ...whitelistedCountries
      ].reduce((acc, cur) => {
        acc.set(cur, cur)
        return acc
      }, new Map())

      commit('setSelectedCountries', [...countriesMap.values()])
    },
    async saveConfigurations(
      { state, getters, dispatch },
      { hasNotification } = { hasNotification: true }
    ) {
      try {
        const configurations = await http().post('configs', {
          ...state.configuration,
          block_countries: state.selected_countries,
          whitelisted_countries: state.selected_countries
        })

        await dispatch('saveLoggingStatus', getters.is_app_enabled)

        if (hasNotification)
          Vue.$toast.open({
            message: `Configuration has been successfully saved`,
            type: 'success',
            dismissible: true
          })

        return configurations
      } catch (error) {
        Vue.$toast.open({
          message: `Error: ${error.response.data.error}`,
          type: 'error',
          dismissible: true
        })
      }
    },
    async saveLoggingStatus(_, payload) {
      try {
        const logging = await http().post('logs/toggle/logging', {
          enable: payload
        })

        return logging
      } catch (error) {
        return true
      }
    }
  }
}
