<template>
  <div class="help flex flex-col h-full">
    <div class="grid grid-cols-2 gap-4 mb-4">
      <DownloadBlocked class="bg-secondary py-4 px-8 shadow-md" />
      <LearnMore class="bg-white border border-theme-border py-4 shadow-md" />
    </div>

    <h1 class="mb-2 text-15px font-sf font-bold">Blocked Visits</h1>
    <div class="border border-theme-border rounded-md bg-white shadow-md p-6">
      <VisitsTable
        :logs="blockedLogs"
        :itemCount="totalBlockedLogs"
        :per-page="perPage"
        @pageChange="pageChange"
      />
    </div>
  </div>
</template>

<script>
import DownloadBlocked from '@/components/DownloadBlocked'
import LearnMore from '@/components/LearnMore'
import VisitsTable from '@/components/VisitsTable'
import { mapActions, mapGetters } from 'vuex'

export default {
  created() {
    this.getBlockedLogs({
      offset: 0,
      limit: this.perPage
    })
  },
  data() {
    return {
      perPage: 7
    }
  },
  computed: {
    ...mapGetters('logs', ['blockedLogs', 'totalBlockedLogs'])
  },
  methods: {
    ...mapActions('logs', ['getBlockedLogs']),
    pageChange(page) {
      this.getBlockedLogs({
        offset: this.perPage * (page - 1),
        limit: this.perPage
      })
    }
  },
  components: {
    DownloadBlocked,
    LearnMore,
    VisitsTable
  }
}
</script>
