import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from '@/components/Auth'
import Login from '@/components/Login'

/** New Pages */
import Index from '@/views/Index'
import Help from '@/views/Help'
import Visits from '@/views/Visits'
import Blocked from '@/views/Blocked'
import AppSetting from '@/views/AppSetting'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/shopify/auth',
    name: 'Auth',
    component: Auth
  },
  /** New Pages */
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/blocked',
    name: 'Blocked',
    component: Blocked
  },
  {
    path: '/visits',
    name: 'Visits',
    component: Visits
  },
  {
    path: '/setting',
    name: 'AppSetting',
    component: AppSetting
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
