import BlockIcon from '@/assets/img/block-icon.svg'
import BlockedIcon from '@/assets/img/blocked-icon.svg'
import VisitsIcon from '@/assets/img/visits-icon.svg'
import SettingsIcon from '@/assets/img/settings-icon.svg'
import HelpIcon from '@/assets/img/help-icon.svg'

export default [
  {
    path: '/',
    name: 'Block',
    icon: BlockIcon
  },
  {
    path: '/blocked',
    name: 'Blocked',
    icon: BlockedIcon
  },
  {
    path: '/visits',
    name: 'Visits',
    icon: VisitsIcon
  },
  {
    path: '/setting',
    name: 'Setting',
    icon: SettingsIcon
  },
  {
    path: '/help',
    name: 'Help',
    icon: HelpIcon
  }
]
