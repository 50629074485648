<template>
  <div class="px-8 py-4 flex-1">
    <div class="flex justify-between items-center mb-4 h-10">
      <div class="grid grid-flow-col gap-2">
        <button
          v-for="tab in tabs"
          :key="tab"
          class="focus:outline-none py-2 px-4 font-light rounded-lg text-14px font-roboto hover:opacity-90"
          :class="{
            'bg-primary text-white': value === tab,
            'bg-disabled text-theme-link bg-opacity-40': value !== tab
          }"
          @click="$emit('input', tab)"
        >
          {{ tab }}
        </button>
      </div>
      <a
        class="text-14px text-theme-link cursor-pointer hover:opacity-90"
        @click="onMoveAll"
      >
        Delete list
      </a>
    </div>
    <div class="w-full">
      <SearchInput class="mb-2" v-model="searchInput" />
      <p class="font-normal font-sf text-14px mb-2">
        {{ helpText }}
      </p>

      <div class="h-60 overflow-y-auto">
        <div
          v-for="country in shownCountries"
          :key="country.code"
          class="flex py-2 px-1 cursor-pointer hover:bg-theme-hover rounded-lg"
          @click="removeCountry(country)"
        >
          <img
            :src="country.img"
            :alt="country.name"
            class="rounded-full h-6 w-6 object-none mr-2 shadow-xl"
          />
          <p class="">{{ country.name }}</p>

          <img
            src="@/assets/img/remove-icon.svg"
            alt="Remove Icon"
            class="ml-auto mr-4 hover:opacity-50"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchInput from '@/components/SearchInput'

import { CTABS } from '@/constants/tabs'
import { mapMutations } from 'vuex'

export default {
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: CTABS.ALLOWED
    }
  },
  data() {
    return {
      tabs: Object.values(CTABS),
      searchInput: ''
    }
  },
  computed: {
    helpText() {
      return this.value === CTABS.ALLOWED
        ? `Add countries from countries list here to 'Allow' visitors from these countries access to your store`
        : `Add countries from countries list to 'Deny' visitors from these countries access to your store`
    },
    shownCountries() {
      return this.countries.filter(country =>
        country.name.toLowerCase().includes(this.searchInput.toLowerCase())
      )
    }
  },
  methods: {
    ...mapMutations('configurations', [
      'removeSelectedCountry',
      'setSelectedCountries'
    ]),
    removeCountry(country) {
      this.removeSelectedCountry(country.code)
    },
    onMoveAll() {
      this.setSelectedCountries([])
    }
  },
  components: {
    SearchInput
  }
}
</script>

<style></style>
