<template>
  <div class="rounded-md flex flex-col items-center">
    <p
      class="text-center text-15px font-bold text-white mb-3 font-sf tracking-wider"
    >
      Download blocked access list
    </p>
    <p class="text-center text-15px text-white w-11/12 font-sf mb-6">
      You can download your logs in csv format, Select the date range then click
      down button. Note that logs are cleaned every 30 days.
    </p>
    <div class="flex justify-between w-full gap-6">
      <date-range-picker
        ref="picker"
        v-model="dateRange"
        class="w-full max-w-date"
        control-container-class="bg-white w-full px-2 py-1 rounded-md cursor-pointer flex gap-2 hover:opacity-90"
      >
        <template v-slot:input="picker">
          <img src="@/assets/img/calendar-icon.svg" alt="Arrow Right" />
          <div class="flex justify-between w-full pr-1">
            {{ picker.startDate | date }}
            <img
              class="w-5"
              src="@/assets/img/arrow-right-icon--black.svg"
              alt="Arrow Right"
            />
            {{ picker.endDate | date }}
          </div>
        </template>
      </date-range-picker>

      <button
        class="focus:outline-none text-white font-roboto rounded-md flex items-center justify-center py-1 px-12 bg-purple border border-theme-border hover:opacity-90"
        @click="onClickDownload"
      >
        <img
          src="@/assets/img/download-icon.svg"
          alt="Download Icon"
          class="mr-3"
        />
        Download
      </button>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      dateRange: {
        startDate: moment()
          .subtract(30, 'days')
          .toDate(),
        endDate: moment().toDate()
      }
    }
  },
  methods: {
    ...mapActions('logs', ['downloadLogs']),
    onClickDownload() {
      this.downloadLogs({
        from: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        to: moment(this.dateRange.endDate).format('YYYY-MM-DD')
      })
    }
  },
  filters: {
    date: function(value) {
      return moment(value).format('DD-MM-YYYY')
    }
  },
  components: {
    DateRangePicker
  }
}
</script>

<style></style>
