<template>
  <div class="help flex flex-col">
    <h1 class="mb-2 text-18px font-roboto">Help</h1>
    <div
      class="border border-theme-border rounded-md bg-white shadow-md p-6 flex-1"
    >
      <p class="text-18px font-roboto font-normal mb-8">
        We put up a guide to give you more information and help you use our app
        effectively.
      </p>

      <p class="text-18px font-roboto font-bold mb-2">
        1. Country Blocker Section:
      </p>
      <p class="text-18px font-roboto font-normal ml-5 mb-8">
        Iploged permits you to easily rule the Country Blocker section, by
        clicking the activation button that allows you to fully block all
        internet traffic to your store from specific countries. Users can easily
        see the list of countries that have access to your stores. Hence, you
        can simply choose and move certain countries you wish to block and
        prevent them from entering your stores. Just click the arrow right → to
        move the chosen countries to the Blocked List Section.
        <span class="block my-4"></span>
        Further, store owners may Unblock countries by naturally clicking the
        “X” mark to remove specific countries from the blocked list section and
        click save to run your preference.
      </p>

      <p class="text-18px font-roboto font-bold mb-2">
        2. IP Blocked Section:
      </p>
      <p class="text-18px font-roboto font-normal ml-5 mb-8">
        Iploged easily allows you to track and monitor the IP address of your
        visitors and permits you to assuredly determine the source of any
        suspicious visitors and to provide an early warning on any possible
        unwanted activities.
        <span class="block my-4"></span>
        You can download your logs in CSV format, Select the Date range then
        click the download button.
        <span class="block my-4"></span>
        Note: that logs are cleaned every 30 days.
      </p>

      <p class="text-18px font-roboto font-bold mb-2">
        3. How long are logs retained?
      </p>
      <p class="text-18px font-roboto font-normal ml-5 mb-6">
        Logs are retained upto a month since you enable logging. Logs are
        periodically deleted after that interval. You can also get a copy of
        those logs before deletion if you enable it in settings.
      </p>

      <p class="text-18px font-roboto font-normal">
        For more information please feel free to contact us at
        <a
          class="text-secondary hover:opacity-80"
          href="mailto:contact@iploged.com"
        >
          contact@iploged.com
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>
